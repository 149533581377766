import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 0 20px;
`

const Terms = ({ location }) => {
  return (
    <Layout location={location}>
      <Container>
        <h1>Terms of Services</h1>
        <p>
          Thanks for using Fireworks Map App! These terms of service (“Terms“)
          cover your use and access to our services, software and websites
          (“Services“). Our Privacy Policy explains how we collect and use your
          information while our Acceptable Use Policy outlines your
          responsibilities when using our Services. By using our Services,
          you’re agreeing to be bound by these Terms, and to review our Privacy
          and Acceptable Use policies. If you’re using our Services for an
          organization, you’re agreeing to these Terms on behalf of that
          organization.
        </p>

        <p>Fireworks Map Services are provided by nabehide and include:</p>

        <ul>
          <li>
            application software that runs on your devices including iPhones,
            iPads and Mac computers (“Apps“)
          </li>
        </ul>
        <p>
          The Services are protected by copyright and other Japanese and foreign
          laws. These Terms don’t grant you any right, title or interest in the
          Services, others’ content in the Services, logos and other brand
          features. We welcome feedback, but note that We may use comments or
          suggestions without any obligation to you.
        </p>

        <h2>Apps Licensing</h2>
        <p>
          Fireworks Map Apps downloaded from Apple’s App Store and/or Apple’s
          Mac App Store are licensed to you under the terms of Apple’s Standard
          LICENSED APPLICATION END USER LICENSE AGREEMENT
        </p>

        <p>
          By using Fireworks Map Apps you are agreeing be bound by the terms of
          the applicable LICENSE. If you do not agree to the terms of the
          License, do not install and/or use the Fireworks Map Apps.
        </p>

        <h2>Termination</h2>
        <p>
          You are free to stop using our Services at any time. We also reserve
          the right to suspend or end the Services at any time at our discretion
          and without notice. For example, We may suspend or terminate your use
          of the Services if you’re not complying with these Terms, or use the
          Services in a manner that would cause us legal liability, disrupt the
          Services or disrupt others’ use of the Services.
        </p>

        <h2>Services “AS IS”</h2>
        <p>
          We strive to provide really good Services, but there are certain
          things that We can’t guarantee. To the fullest extent permitted by
          law, we makes no warranties, expressed or implied, about the Services.
          The Services are provided “AS IS”. We also disclaim any warranties of
          merchantability, fitness for a particular purpose and
          non-infringement. Further, we does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the material on its Site, any site linked or use of the
          Services. Some places don’t allow the disclaimers in this paragraph,
          so they may not apply to you.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          In no event shall we or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption), arising out of the use or inability to
          use the Services even if we or any of its authorized representatives
          has been notified of the possibility of such damage. Some places don’t
          allow the types of limitations in this paragraph, so they may not
          apply to you.
        </p>

        <h2>Revisions and Errata</h2>
        <p>
          The materials appearing within Our Services could include technical,
          typographical, or photographic errors. We do not warrant that any of
          the materials within our Services are accurate, complete, or current.
          We may make changes to the content in the Services at any time without
          notice. We do not, however, make any commitment to update the
          materials.
        </p>

        <h2>Site Terms of Use Modifications</h2>
        <p>
          We may revise these terms of use for its web site at any time without
          notice. By using this web site you are agreeing to be bound by the
          then current version of these Terms and Conditions of Use.
        </p>

        <h2>Governing Law</h2>
        <p>
          Any claim relating to Our Services shall be governed by the laws of
          Japan.
        </p>

        <h2>Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and us with
          respect to the subject matter of these Terms, and supersede and
          replace any other prior or contemporaneous agreements, or terms and
          conditions applicable to the subject matter of these Terms. These
          Terms create no third party beneficiary rights.
        </p>

        <h2>Waiver, Severability & Assignment</h2>
        <p>
          We failure to enforce a provision is not a waiver of its right to do
          so later. If a provision is found unenforceable, the remaining
          provisions of the Terms will remain in full effect and an enforceable
          term will be substituted reflecting our intent as closely as possible.
          You may not assign any of your rights under these Terms, and any such
          attempt will be void. We may assign its rights to any successor in
          interest of any business associated with the Services.
        </p>

        <h2>Contact us</h2>
        <p>
          If you want to provide feedback, have any questions or need
          clarification, please contact us via the app.
        </p>
      </Container>
    </Layout>
  )
}

export default Terms

export const Head = ({ location: { pathname } }) => (
  <Seo title="Fireworks Map Japan | Terms of Services" pathname={pathname} />
)
